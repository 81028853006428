import React from 'react'
import {Button, Card} from "reactstrap";
import NotesEditor from "../NotesEditor";

const Notes = (props) =>
{
    return (
        <Card
            style={{
                display: "flex",
                width: "50%",
                borderRadius: "5px",
                margin: "auto",
                marginTop: "5rem",
                marginBottom: "5rem",
                padding: "20px",
                boxShadow: "3px 5px 33px lightgrey",
            }}
        >
            <h2 style={{marginBottom: "20px"}}>Mes notes</h2>
            <NotesEditor/>

        </Card>
    )
}

export default Notes
