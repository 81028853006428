import React from 'react'
import {Button, Card} from "reactstrap";
import {Replay} from "@material-ui/icons";
const data = [
    {
        number: 1,
        response: "Je m'appelle Nina"
    },
    {
        number: 2,
        response: "Je m'appelle Nina"
    },
    {
        number: 3,
        response: "Je m'appelle Nina"
    },
    {
        number: 4,
        response: "Je m'appelle Nina"
    }

]
const Quizz = (props) =>
{
    return (
        <Card
            style={{
                display: "flex",
                width: "50%",
                borderRadius: "5px",
                margin: "auto",
                marginTop: "5rem",
                marginBottom: "5rem",
                padding: "20px",
                boxShadow: "3px 5px 33px lightgrey",
            }}
        >
            <div style={{display: "flex"}}>
                <p style={{
                    flexBasis: "10%",
                    marginBottom: 0,
                    fontWeight: "600",
                    fontSize: "20px",
                    textAlign: "center",
                    border: "2px solid #2A3144",
                    borderRadius: "20px",
                    paddingBottom: "7px",
                    padding: "4px"
                }}>1/X</p>
            </div>
            <p style={{textAlign: "center", fontWeight: "600", fontSize: "25px", marginBottom: "40px"}}>La question ?</p>
            {data.map(elt => (
                <div style={{display: "flex", marginTop: "15px"}} >
                    <div style={{flexBasis: "10%"}}></div>
                    <div style={{display: "flex", flexBasis: "80%", justifyContent: "center"}}>
                        <p style={{fontWeight: "600", fontSize: "25px", marginRight: "30px"}}>{elt.number}.</p>
                        <p className="button_quizz_normal" onClick={(e) => console.log(e)}>{elt.response}</p>
                    </div>
                    <div style={{flexBasis: "10%"}}></div>
                </div>
            ))}
            <div style={{display: "flex", marginTop: "30px"}}>
                <div style={{flexBasis: "10%"}}></div>
                <div style={{display: "flex", flexBasis: "80%", justifyContent: "center"}}>
                    <Replay style={{marginRight: "50px", border: "1px solid #2A3144", borderRadius: "50px"}}/>
                    <p style={{fontWeight: "600", fontSize: "20px", color: "green"}}>Bonne réponse</p>
                </div>
                <div style={{flexBasis: "10%"}}>
                    <Button color="success" style={{borderRadius: "50px"}}>
                        Valider
                    </Button>
                </div>
            </div>

        </Card>
    )
}

export default Quizz
