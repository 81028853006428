import React from 'react'
import MenuPlatform from "../components/Menu/MenuPlatform";
import {ArrowBackIos, ArrowForwardIos, ArrowLeft, ArrowRight} from "@material-ui/icons";
import Quizz from "../components/CoursesElements/Quizz";
import Notes from "../components/CoursesElements/Notes";

const OneQuizz = (props) =>
{
    return(
        <>
            <div style={{ display: "flex", margin: "10px" }}>
                <p style={{ margin: "auto" }}>Tableau de bord / Mes Quizz / Cours X</p>
                <MenuPlatform />
            </div>
            <div style={{display: "flex", justifyContent: "center"}}>
                <div style={{flexBasis: "10%", margin: "auto", textAlign: "right"}}><ArrowBackIos style={{fontSize: "50px"}}/></div>
                <div style={{flexBasis: "80%", margin: "auto"}}><Quizz/></div>
                <div style={{flexBasis: "10%", margin: "auto"}}><ArrowForwardIos style={{fontSize: "50px"}}/></div>
            </div>
        </>
    )
}

export default OneQuizz
