import React from "react";
import MenuPlatform from "../components/Menu/MenuPlatform";
import FormInfos from "../components/Form/FormInfos";

const MesInformations = (
) => {

  return (
    <div>
      <div style={{ display: "flex", margin: "10px" }}>
        <p style={{ margin: "auto" }}>Mes informations</p>
        <MenuPlatform />
      </div>
      <FormInfos/>
    </div>
  );
};

export default MesInformations;
