import React from "react";
import { useState } from "react";
import { Card, Input, Button} from "reactstrap";

const FormPassword = () => {
  const [old, setOld] = useState("");
  const [new_passowrd, setNewpassword] = useState("");
  const [confirmer, setConfirmer] = useState("");
  return (
    <div>
      <Card style={{
        display: "flex",
        width: "50%",
        margin: "auto",
        marginTop: "5rem",
        padding: "20px",
      }}>
        <h2>Modifier mon mot de passe</h2>
        <h5>Ancien Mot de Passe</h5>
        <Input
          type="password"
          value={old}
          onChange={(e) => setOld(e.target.value)}
        />
        <h5>Nouveau Mot de Passe</h5>
        <Input
          type="password"
          value={new_passowrd}
          onChange={(e) => setNewpassword(e.target.value)}
        />
        <h5>Confirmer Mot de Passe</h5>
        <Input
          type="password"
          value={confirmer}
          onChange={(e) => setConfirmer(e.target.value)}
        />
        <div style={{textAlign: "right"}}>
        <Button color="success" style={{width: "30%", marginTop: "15px"}}>Confirmer</Button>
        </div>
      </Card>
    </div>
  );
};

export default FormPassword;