import React from "react";
import MenuPlatform from "../components/Menu/MenuPlatform";
import Flag from "../assets/svg/flag.svg";
import Graduate from "../assets/svg/graduate.svg";
import AssignmentReturnedIcon from "@material-ui/icons/AssignmentReturned";
import ProgressBar from "../components/CoursesElements/ProgressBar";
import NotesEditor from "../components/NotesEditor";
import {ArrowBackIos, ArrowForwardIos} from "@material-ui/icons";
import Quizz from "../components/CoursesElements/Quizz";

const OneCours = () => {
    return (
        <div>
            <div style={{ display: "flex", margin: "10px" }}>
                <p style={{ margin: "auto" }}>Tableau de bord / Nom du cours</p>
                <MenuPlatform />
            </div>
            <div
                style={{
                    borderRadius: "20px",
                    marginTop: "30px",
                    margin: "auto",
                    display: "flex",
                    background: "white",
                    boxShadow: "0px 0px 25px lightgrey",
                    width: "60%",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                }}
            >
                <img src={Flag} alt="Flag.." style={{ width: "70px" }} />
                <ProgressBar />
                <ProgressBar />
                <ProgressBar />
                <ProgressBar />
                <ProgressBar />

                <img src={Graduate} alt="Graduate.." style={{ width: "70px" }} />
            </div>

            <h2 style={{ marginTop: "20px", marginBottom: "20px" }}>
                Partie du cours
            </h2>
            <div style={{display: "flex", justifyContent: "center"}}>
                <div style={{flexBasis: "10%", margin: "auto", textAlign: "right"}}><ArrowBackIos style={{fontSize: "50px"}}/></div>
                <div
                    id="square"
                    style={{
                        margin: "auto",
                        marginBottom: "20px",
                        borderRadius: "10px",
                        width: "60%",
                        height: "400px",
                        background: "black",
                    }}
                ></div>
                <div style={{flexBasis: "10%", margin: "auto"}}><ArrowForwardIos style={{fontSize: "50px"}}/></div>
            </div>

            <div
                style={{
                    margin: "auto",
                    borderRadius: "10px",
                    padding: "10px",
                    width: "60%",
                    justifyContent: "left",
                    background: "white",
                    boxShadow: "0px 0px 25px lightgrey",
                }}
            >
                <h2 style={{textAlign: "left", paddingLeft: "10px"}}>Mes notes</h2>
                <NotesEditor />
            </div>

            <div
                style={{
                    width: "60%",
                    margin: "auto",
                    marginTop: "20px",
                    marginBottom: "20px",
                }}
            >
                <div
                    style={{
                        justifyContent: "center",
                        borderRadius: "10px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        width: "30%",
                        display: "flex",
                        background: "white",
                        boxShadow: "0px 0px 25px lightgrey",
                    }}
                >
                    <AssignmentReturnedIcon style={{ marginRight: "10px" }} />
                    <p style={{ marginBottom: 0 }}>PDF - Résumé - Chapitre 1</p>
                </div>
            </div>
        </div>
    );
};

export default OneCours;

//natacha.baumann@bnpparibas.com
