import "./assets/css/Switching.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import MesFormations from "./pages/MesFormations";
import MesQuizz from "./pages/MesQuizz";
import MesCours from "./pages/MesCours";
import MesNotes from "./pages/MesNotes";
import MesInformations from "./pages/MesInformations";
import MesMdp from "./pages/MesMdp";
import { Route, Switch } from "react-router-dom";
import NotFound from "./pages/NotFound";
import OneCours from "./pages/OneCours";
import Dashboard from "./pages/Dashboard";
import Certificat from "./components/CoursesElements/Certificat";
import Quizz from "./components/CoursesElements/Quizz";
import Notes from "./components/CoursesElements/Notes"
import OneQuizz from "./pages/OneQuizz";
import OneNotes from "./pages/OneNotes";
import ReceiptMessage from "./pages/ReceiptMessage";
import SendMessage from "./pages/SendMessage";
import ReadMessage from "./pages/ReadMessage";
import NewMessage from "./pages/NewMessage";
import ReadSendMessage from "./pages/ReadSendMessage";

function App() {
  return (
    <div>
      <Header />
      <Switch>
        <Route exact path="/" component={MesFormations} />
        <Route path="/quizz/:id" component={OneQuizz} />
        <Route path="/quizz" component={MesQuizz} />
        <Route path="/cours/:id" component={OneCours} />
        <Route path="/cours" component={MesCours} />
        <Route path="/notes/:id" component={OneNotes} />
        <Route path="/notes" component={MesNotes} />
        <Route path="/mes-informations" component={MesInformations} />
        <Route path="/mes-mots-de-passe" component={MesMdp} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/messagerie/nouveau-message" component={NewMessage} />
        <Route path="/messagerie/read-message/:id" component={ReadMessage} />
        <Route path="/messagerie/send-message/:id" component={ReadSendMessage} />
        <Route path="/messagerie/message-envoye" component={SendMessage} />
        <Route path="/messagerie" component={ReceiptMessage} />
        <Route component={ NotFound } />
      </Switch>
    </div>
  );
}

export default App;
