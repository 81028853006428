import React from 'react'
import MenuPlatform from "../components/Menu/MenuPlatform";
import {Button, Card, Input} from "reactstrap";
import {Edit} from "@material-ui/icons";
import {TextareaAutosize} from "@material-ui/core";
import {useHistory} from "react-router-dom";

const NewMessage = (props) =>
{
    let history = useHistory()

    return (
        <div>
            <div style={{ display: "flex", margin: "10px" }}>
                <p style={{ margin: "auto" }}>Tableau de bord / Messagerie / Nouveau message</p>
                <MenuPlatform />
            </div>
            <Card
                style={{
                    display: "flex",
                    width: "50%",
                    borderRadius: "5px",
                    margin: "auto",
                    marginTop: "5rem",
                    marginBottom: "5rem",
                    padding: "20px",
                    boxShadow: "3px 5px 33px lightgrey",
                }}
            >
                <h2 style={{marginBottom: "35px"}}>Messagerie</h2>
                <div style={{display: "flex"}}>
                    <div style={{flexBasis: "20%", marginLeft: "auto",paddingTop: "10px", marginRight: "auto"}}>
                        <Button style={{
                            background: "white",
                            boxShadow: "3px 5px 25px lightgrey",
                            border: 0,
                            borderRadius: "50px",
                            color: "#2A3144",
                            fontSize: "12px",
                            marginBottom: "10px",
                        }} onClick={() => history.push("/messagerie/nouveau-message")}> <Edit style={{fontSize: "13px"}} /> Nouveau Message</Button>
                        <Button style={{
                            background: "transparent",
                            color: "#2A3144",
                            border: 0,
                            padding: 0,
                        }} onClick={() => history.push("/messagerie")}>Messsage reçu(s)</Button>
                        <br/>
                        <Button style={{
                            background: "transparent",
                            color: "#2A3144",
                            border: 0,
                            padding: 0,
                        }} onClick={() => history.push("/messagerie/message-envoye")}>Message envoyé(s)</Button>
                    </div>
                    <div style={{
                        flexBasis: "70%",
                        boxShadow: "3px 5px 25px lightgrey",
                        borderRadius: "10px",
                        margin: "auto",
                        paddingTop: "10px",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        overflowY: "scroll",
                        overflowX: "hidden",
                        height: "25rem"
                    }}>
                        <div style={{display: "flex"}}>
                            <p style={{margin: "auto 20px auto 10px"}}>Destinataire:</p>
                            <select style={{
                                margin: "auto 20px auto 10px",
                                border: 0,
                                background: "transparent",
                                fontSize: "18px",
                                color: "#2A3144",
                                fontWeight: "600"
                            }}>
                                <option>Conseiller pédagogique</option>
                                <option>Adminstrateur</option>
                            </select>
                        </div>
                        <div style={{display: "flex"}}>
                            <p style={{margin: "auto 20px auto 10px"}}>Objet:</p>
                            <Input placeholder="Votre objet" style={{
                                margin: "auto 20px auto 10px",
                                border: 0,
                                background: "transparent",
                                fontSize: "15px",
                                color: "#2A3144",
                                fontWeight: "600",
                                outline: 0
                            }}/>
                        </div>
                        <textarea  style={{
                            margin: "auto 20px auto 10px",
                            border: 0,
                            background: "transparent",
                            fontSize: "15px",
                            color: "#2A3144",
                            fontWeight: "600",
                            outline: 0,
                            width: "100%",
                            height: "50%"
                        }} placeholder="Rédigez votre message..."></textarea>
                        <div style={{display: "flex", justifyContent: "right"}}>
                            <Button style={{
                                marginTop: "20px",
                                background: "linear-gradient(90deg, rgba(38,57,84,1) 25%, rgba(0,185,238,1) 100%)",
                                border: 0,
                                borderRadius: "20px"
                            }}>Envoyer</Button>
                        </div>

                    </div>
                </div>
            </Card>
        </div>
    )
}

export default NewMessage;
