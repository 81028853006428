import React from "react";
import MenuPlatform from "../components/Menu/MenuPlatform";
import FormPassword from "../components/Form/FormPassword";

const MesMdp = () => {
  return (
    <div>
      <div style={{ display: "flex", margin: "10px" }}>
        <p style={{ margin: "auto" }}>Mot de passe</p>
        <MenuPlatform />
      </div>
      <FormPassword/>
    </div>
  );
};

export default MesMdp;
