import React from "react";
import { useHistory } from "react-router-dom";
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";

const MenuPlatform = () => {
  let history = useHistory();
  return (
    <UncontrolledDropdown style={{ margin: "auto" }}>
      <DropdownToggle
        aria-expanded={false}
        aria-haspopup={true}
        caret
        style={{backgroundColor: "#2A3144"}}
        data-toggle="dropdown"
        href="#pablo"
        id="dropdownMenuLink"
        onClick={(e) => e.preventDefault()}
        role="button"
      >
        Menu
      </DropdownToggle>
      <DropdownMenu aria-labelledby="dropdownMenuLink">
        <DropdownItem href="#pablo" onClick={(e) => {
          e.preventDefault();
          history.push("/dashboard")
        }}>
          Tableau de bord
        </DropdownItem>
        <DropdownItem href="#pablo" onClick={(e) => {
          e.preventDefault();
          history.push("/cours")
        }}>
          Mes cours
        </DropdownItem>
        <DropdownItem href="#pablo" onClick={(e) => {
          e.preventDefault();
          history.push("/notes")
        }}>
          Mes notes
        </DropdownItem>
        <DropdownItem href="#pablo" onClick={(e) => {
          e.preventDefault();
          history.push("/quizz")
        }}>
          Quizz
        </DropdownItem>
        <DropdownItem href="#pablo" onClick={(e) => {
          e.preventDefault();
          history.push("/messagerie")
        }}>
          Messagerie
        </DropdownItem>
        <DropdownItem href="#pablo" onClick={(e) => {
          e.preventDefault();
          history.push("/actus")
        }}>
          Actualités
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default MenuPlatform;
