import React from "react";
import { useState } from "react";
import { Card, Input } from "reactstrap";

const FormInfos = () => {
  const [prenom, setPrenom] = useState("");
  const [lastname, setLastname] = useState("");
  const [birthday, setBirthday] = useState("");
  return (
    <div>
      <Card style={{
        display: "flex",
        width: "50%",
        margin: "auto",
        marginTop: "5rem",
        padding: "20px",
      }}>
        <h2>Mes Informations</h2>
        <h5>Prénom</h5>
        <Input
          type="text"
          value={prenom}
          onChange={(e) => setPrenom(e.target.value)}
        />
        <h5>Nom de famille</h5>
        <Input
          type="text"
          value={lastname}
          onChange={(e) => setLastname(e.target.value)}
        />
        <h5>Date de naissance</h5>
        <Input
          type="date"
          value={birthday}
          onChange={(e) => setBirthday(e.target.value)}
        />
      </Card>
    </div>
  );
};

export default FormInfos;