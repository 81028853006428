import React from "react";

import "../assets/css/Switching.css";
import "../assets/css/Table.scss";
import MenuPlatform from "../components/Menu/MenuPlatform";
import AccessInfos from "../components/AccessInfos/AccessInfos";

const MesQuizz = (props) => {
  return (
    <div style={{ textAlign: "center" }}>
      <div style={{ display: "flex", margin: "10px" }}>
        <p style={{ margin: "auto" }}>Tableau de bord / Mes quizz</p>
        <MenuPlatform />
      </div>
      <AccessInfos
        title="Mes Quizz"
        url="quizz"
        col1="Cours"
        col2="Visualiser"
        col3="Effectuer"
      />
    </div>
  );
};

export default MesQuizz;
