import React from 'react'
import MenuPlatform from "../components/Menu/MenuPlatform";
import Notes from "../components/CoursesElements/Notes";
import {ArrowBackIos, ArrowForwardIos, ArrowLeft, ArrowRight} from "@material-ui/icons";

const OneNotes = (props) =>
{
    return(
        <>
        <div style={{ display: "flex", margin: "10px" }}>
            <p style={{ margin: "auto" }}>Tableau de bord / Mes Notes / Cours X</p>
            <MenuPlatform />
        </div>
        <div style={{display: "flex", justifyContent: "center"}}>
            <div style={{flexBasis: "10%", margin: "auto", textAlign: "right"}}><ArrowBackIos style={{fontSize: "50px"}}/></div>
            <div style={{flexBasis: "80%", margin: "auto"}}><Notes/></div>
            <div style={{flexBasis: "10%", margin: "auto"}}><ArrowForwardIos style={{fontSize: "50px"}}/></div>
        </div>
        </>
    )
}

export default OneNotes
