import React from "react";
import { useHistory } from "react-router-dom";
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";
import Logo from "../assets/logo/eswitch.svg"

const Header = () => {
  let history = useHistory();
  return (
    <div
      style={{
        display: "flex",
        paddingTop: "20px",
        paddingBottom: "20px",
        boxShadow: "0px 2px 8px lightgrey",
        marginBottom: "50px",
      }}
    >
      
      <img style={{width: "15%", margin: "auto", marginBottom: "10px"}} src={Logo} alt="e-switch Logo"/>
      <a href="/" style={{ margin: "auto" }}>
        Retour vers le site
      </a>
      <UncontrolledDropdown style={{ margin: "auto" }}>
        <DropdownToggle
          aria-expanded={false}
          aria-haspopup={true}
          caret
          color="success"
          data-toggle="dropdown"
          href="#pablo"
          id="dropdownMenuLink"
          onClick={(e) => e.preventDefault()}
          role="button"
        >
          Mon profil
        </DropdownToggle>
        <DropdownMenu aria-labelledby="dropdownMenuLink">
          <DropdownItem
            href="#pablo"
            onClick={(e) => {
              e.preventDefault();
              history.push("/");
            }}
          >
            Mes formations
          </DropdownItem>
          <DropdownItem
            href="#pablo"
            onClick={(e) => {
              e.preventDefault();
              history.push("/mes-informations");
            }}
          >
            Mes informations
          </DropdownItem>
          <DropdownItem href="#pablo" onClick={(e) => {
              e.preventDefault();
              history.push("/mes-mots-de-passe");
            }}>
            Mot de passe
          </DropdownItem>
          <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
            Factures
          </DropdownItem>
          <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
            Deconnexion
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
};

export default Header;
