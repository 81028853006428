import React from "react";
import MenuPlatform from "../components/Menu/MenuPlatform";
import {Button} from "reactstrap"
import { Doughnut } from "react-chartjs-2";

const data = {
  datasets: [
    {
      data: [25, 75],
      backgroundColor: ["rgb(139, 223, 224)", "rgb(232, 233, 237)"],
      borderWidth: 0,
      offset: 1,
      hoverOffset: 4,
    },
  ],
};
const Dashboard = (props) => {
  return (
    <div>
      <div style={{ display: "flex", margin: "10px" }}>
        <h2 style={{ margin: "auto", textAlign: "right" }}>Tableau de bord</h2>
        <MenuPlatform />
      </div>
      <div style={{ width: "350px", margin: "auto", marginTop: "10px", textAlign: "center" }}>
        <Doughnut data={data} />
        <p style={{fontWeight: 800, fontSize: "40px", marginTop: "-200px"}}>25%</p>
        <Button style={{backgroundColor: "#2A3144", marginTop: "200px"}}>Je continue</Button>
      </div>
      
    </div>
  );
};

export default Dashboard;
