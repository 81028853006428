import React from "react";
import {Card, InputGroup, InputGroupAddon, InputGroupText, Input} from 'reactstrap'
import Eyes from "@material-ui/icons/Visibility";
import SearchIcon from "@material-ui/icons/Search";
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import { useHistory } from "react-router-dom";

const AccessInfos = (props) => {
  let history = useHistory();
  return (
    <Card
      style={{
        display: "flex",
        width: "50%",
        margin: "auto",
        marginTop: "5rem",
        padding: "10px",
      }}
    >
      <h2 style={{ margin: "10px" }}>{props.title}</h2>
      <InputGroup
        style={{
          width: "90%",
          margin: "auto",
          marginTop: "10px",
          marginBottom: "10px",
        }}
      >
        <Input placeholder="Rechercher"></Input>
        <InputGroupAddon addonType="append">
          <InputGroupText>
            <SearchIcon />
          </InputGroupText>
        </InputGroupAddon>
      </InputGroup>
      <div
        classNamee="container"
        style={{ overflowY: "scroll", height: "25rem" }}
      >
        <ul class="responsive-table">
          <li class="table-header">
            <div class="col col-3">{props.col1}</div>
            <div class="col col-3">{props.col2}</div>
            <div class="col col-4">{props.col3}</div>
          </li>
          <li class="table-row">
            <div class="col col-3" data-label={props.col1}>
              Name
            </div>
            <div class="col col-3" data-label={props.col2}>
              <Eyes className="eyes" onClick={() => history.push(`/${props.url}/1`)}/>
            </div>
            <div class="col col-4" data-label={props.col3}>
                {props.col3 === "Effectuer" ? <Input type="checkbox" /> : null}
                {props.col3 === "Téléchargements" ? <AssignmentReturnedIcon className="eyes assignment"/> : null}
            </div>
          </li>
          <li class="table-row">
            <div class="col col-3" data-label={props.col1}>
              Name
            </div>
            <div class="col col-3" data-label={props.col2}>
              <Eyes className="eyes" onClick={() => history.push(`/${props.url}/1`)}/>
            </div>
            <div class="col  col-4" data-label={props.col3}>
                {props.col3 === "Effectuer" ? <Input type="checkbox" /> : null}
                {props.col3 === "Téléchargements" ? <AssignmentReturnedIcon className="eyes assignment"/> : null}
            </div>
          </li>
          <li class="table-row">
            <div class="col col-3" data-label={props.col1}>
              Name
            </div>
            <div class="col col-3" data-label={props.col2}>
              <Eyes className="eyes" onClick={() => history.push(`/${props.url}/1`)}/>
            </div>
            <div class="col  col-4" data-label={props.col3}>
                {props.col3 === "Effectuer" ? <Input type="checkbox" /> : null}
                {props.col3 === "Téléchargements" ? <AssignmentReturnedIcon className="eyes assignment"/> : null}
            </div>
          </li>
          <li class="table-row">
            <div class="col col-3" data-label={props.col1}>
              Name
            </div>
            <div class="col col-3" data-label={props.col2}>
              <Eyes className="eyes" onClick={() => history.push(`/${props.url}/1`)}/>
            </div>
            <div class="col  col-4" data-label={props.col3}>
                {props.col3 === "Effectuer" ? <Input type="checkbox" /> : null}
                {props.col3 === "Téléchargements" ? <AssignmentReturnedIcon className="eyes assignment"/> : null}
            </div>
          </li>
          <li class="table-row">
            <div class="col col-3" data-label={props.col1}>
              Name
            </div>
            <div class="col col-3" data-label={props.col2}>
              <Eyes className="eyes" onClick={() => history.push(`/${props.url}/1`)}/>
            </div>
            <div class="col  col-4" data-label={props.col3}>
                {props.col3 === "Effectuer" ? <Input type="checkbox" /> : null}
                {props.col3 === "Téléchargements" ? <AssignmentReturnedIcon className="eyes assignment"/> : null}
            </div>
          </li>
          <li class="table-row">
            <div class="col col-3" data-label={props.col1}>
              Name
            </div>
            <div class="col col-3" data-label={props.col2}>
              <Eyes className="eyes" onClick={() => history.push(`/${props.url}/1`)}/>
            </div>
            <div class="col  col-4" data-label={props.col3}>
                {props.col3 === "Effectuer" ? <Input type="checkbox" /> : null}
                {props.col3 === "Téléchargements" ? <AssignmentReturnedIcon className="eyes assignment"/> : null}
            </div>
          </li>
          <li class="table-row">
            <div class="col col-3" data-label={props.col1}>
              Name
            </div>
            <div class="col col-3" data-label={props.col2}>
              <Eyes className="eyes" onClick={() => history.push(`/${props.url}/1`)}/>
            </div>
            <div class="col  col-4" data-label={props.col3}>
                {props.col3 === "Effectuer" ? <Input type="checkbox" /> : null}
                {props.col3 === "Téléchargements" ? <AssignmentReturnedIcon className="eyes assignment"/> : null}
            </div>
          </li>
        </ul>
      </div>
    </Card>
  );
};

export default AccessInfos;