import React from "react";

const ProgressBar = (props) => {
  return (
    <div
      style={{
       marginRight: "20px",
        display: "flex",
        background: "white",
        width: "20%",
      }}
    >
      <div
        id="circle"
        style={{
          margin: "auto",
          width: "25px",
          height: "25px",
          borderRadius: "20px",
          background: "white",
          border: "1px solid black",
        }}
      ></div>
      <div
        id="circle"
        style={{
          margin: "auto",
          width: "25px",
          height: "25px",
          borderRadius: "20px",
          background: "white",
          border: "1px solid black",
        }}
      ></div>
      <div
        id="circle"
        style={{
          margin: "auto",
          width: "25px",
          height: "25px",
          borderRadius: "20px",
          background: "white",
          border: "1px solid black",
        }}
      ></div>
      <div
        id="square"
        style={{
          margin: "auto",
          width: "25px",
          height: "25px",
          background: "white",
          border: "1px solid black",
        }}
      ></div>
    </div>
  );
};

export default ProgressBar;
