import React from 'react'
import MenuPlatform from "../components/Menu/MenuPlatform";
import {Button, Card} from "reactstrap";
import {Edit} from "@material-ui/icons";
import {useHistory} from "react-router-dom";

const data = [
    {
        id: 1,
        date: "12/03/2021",
        dest: "Incompréhension cours"
    },
    {
        id: 2,
        date: "12/05/2021",
        dest: "Problème de connexion"
    },
    {
        id: 3,
        date: "12/06/2021",
        dest: "Demande de RDV"
    },
    {
        id: 4,
        date: "12/07/2021",
        dest: "Attestation de cours"
    },
    {
        id: 5,
        date: "12/08/2021",
        dest: "QCM 1"
    },
    {
        id: 6,
        date: "12/08/2021",
        dest: "QCM"
    },
    {
        id: 7,
        date: "12/08/2021",
        dest: "Incompréhension cours"
    },
    {
        id: 8,
        date: "12/08/2021",
        dest: "Attestation de cours"
    },
    {
        id: 9,
        date: "12/08/2021",
        dest: "Bonjour"
    },
]

const SendMessage = (props) =>
{

    let history = useHistory();
    return (
        <div>
            <div style={{ display: "flex", margin: "10px" }}>
                <p style={{ margin: "auto" }}>Tableau de bord / Messagerie / Message envoyé(s)</p>
                <MenuPlatform />
            </div>
            <Card
                style={{
                    display: "flex",
                    width: "50%",
                    borderRadius: "5px",
                    margin: "auto",
                    marginTop: "5rem",
                    marginBottom: "5rem",
                    padding: "20px",
                    boxShadow: "3px 5px 33px lightgrey",
                }}
            >
                <h2 style={{marginBottom: "35px"}}>Messagerie</h2>
                <div style={{display: "flex"}}>
                    <div style={{flexBasis: "20%", marginLeft: "auto",paddingTop: "10px", marginRight: "auto"}}>
                        <Button style={{
                            background: "white",
                            boxShadow: "3px 5px 25px lightgrey",
                            border: 0,
                            borderRadius: "50px",
                            color: "#2A3144",
                            fontSize: "12px",
                            marginBottom: "10px",
                        }} onClick={() => history.push("/messagerie/nouveau-message")}> <Edit style={{fontSize: "13px"}} /> Nouveau Message</Button>
                        <Button style={{
                            background: "transparent",
                            color: "#2A3144",
                            border: 0,
                            padding: 0,
                        }} onClick={() => history.push("/messagerie")}>Messsage reçu(s)</Button>
                        <br/>
                        <Button style={{
                            background: "transparent",
                            color: "#2A3144",
                            fontWeight: 600,
                            border: 0,
                            padding: 0,
                        }} onClick={() => history.push("/messagerie/message-envoye")}>Message envoyé(s)</Button>
                    </div>
                    <div style={{
                        flexBasis: "70%",
                        margin: "auto",
                        paddingTop: "10px",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        overflowY: "scroll",
                        height: "25rem"
                    }}>
                        {data.map((elt) => (
                            <div style={{
                                display: "flex",
                                textAlign: "center",
                                boxShadow: "3px 5px 10px lightgrey",
                                paddingTop: "15px",
                                paddingBottom: "15px",
                                marginBottom: "10px",
                                cursor: "pointer",
                            }} key={elt.id} onClick={() => history.push(`/messagerie/send-message/${elt.id}`)}>
                                <p style={{flexBasis: "20%", margin: "auto", fontSize: "18px"}}>{elt.date}</p>
                                <p style={{flexBasis: "70%", margin: "auto", fontSize: "18px"}}>{elt.dest}</p>
                            </div>
                        ) )}

                    </div>
                </div>
            </Card>
        </div>
    )
}

export default SendMessage
