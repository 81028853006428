import React from "react";
import img1 from "../assets/img/bg1.jpg";
import img2 from "../assets/img/bg3.jpg";
import Icon from "@material-ui/icons/Phone";
import { useHistory } from "react-router-dom";

const MesFormations = () => {
  let history = useHistory();

  return (
    <div style={{ display: "flex", height: "80vh" }}>
      <div style={{ textAlign: "center", margin: "auto" }}>
        <h3>Mes formations</h3>
        <div
          style={{
            display: "flex",
            marginTop: "30px",
          }}
        >
          <button
            style={{
              textAlign: "right",
              marginRight: "50px",
              border: 0,
              background: "transparent",
              padding: "25px"
            }}
            onClick={() => {
              history.push("/cours");
            }}
          >
            <img
              alt="..."
              src={img1}
              width="60%"
              style={{
                border: "10px solid white",
                boxShadow: "3px 5px 33px lightgrey",
                borderRadius: "10px",
              }}
            />
          </button>
          <button
            style={{
              textAlign: "left",
              marginLeft: "50px",
              border: 0,
              background: "transparent",
              padding: "25px"
            }}
            onClick={() => {
              history.push("/cours");
            }}
          >
            <img
              alt="..."
              src={img2}
              width="60%"
              style={{
                border: "10px solid white",
                boxShadow: "3px 5px 33px lightgrey",
                borderRadius: "10px",
              }}
            />
          </button>
        </div>
        <p style={{ marginTop: "10%" }}>
          <Icon style={{ marginRight: "10px" }} />
          Nos conseillers sont à votre écoute au 01 33 45 67 88
        </p>
      </div>
    </div>
  );
};

export default MesFormations;
