import React, { useRef } from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

const NotesEditor = (props) => {
  const editor = useRef();
  const getSunEditorInstance = (sunEditor) => {
    editor.current = sunEditor;
  };
  return <SunEditor getSunEditorInstance={getSunEditorInstance} />;
};

export default NotesEditor;
